<template>
  <div
    id="GoTop"
    @click="GoTop()"
  >
    <span class="glyphicon glyphicon-chevron-up" />
  </div>
</template>
<script>
export default {
  name: 'GoTop',
  data () {
    return {
      flag: false
    }
  },
  methods: {
    GoTop () {
      (function smoothscroll () {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll)
          window.scrollTo(0, currentScroll - currentScroll / 10)
        }
      }())
    }
  }
}
</script>
<style scoped>
#GoTop {
  width: 62px;
  height: 62px;
  position: fixed;
  /*right: 0;*/
  bottom: 51px;
  z-index: 100;
  background: #007aff;
  border-radius: 50%;
  cursor: pointer;
}

#GoTop > span {
  display: block;
  width: 100%;
  height: 100%;
  /*color: rgb(8, 162, 233);*/
  color: #fff;
  font-size: 30px;
  top: 15px;
  margin: 0 auto;
  text-align: center;
}
</style>
