<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <GoTop />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: '微软雅黑', Verdana, Arial, Helvetica, sans-serif;
  font-weight: normal;
}
</style>
