<template>
  <div
    id="footer"
    class="container-fluid"
  >
    <div class="logo">
      <img
        src="@/assets/img/logo_white.png"
        alt="logo图"
        width="100%"
        height="130%"
      >
    </div>
    <p class="title">上海来店信息技术有限公司</p>
    <p class="address_tel_fax">
      <span>地址：上海市长宁区愚园路1107号创邑SPACE 1号楼4楼 &emsp;</span>
      <span>Tel：021-6233 9576</span>
    </p>
    <p class="email_wx">
      <span>邮箱：service@laidian360.com &emsp;</span>
      <span>公司微信号：laidian360</span>
    </p>
    <p class="copy">Copyright © 2016-2019 来店 沪ICP备16037730号</p>

    <a
      href="https://xyt.xcc.cn/getpcInfo?sn=1517434654028689408&language=CN&certType=8&url=*.laidian360.com"
      target="_blank"
      style="position: relative;display: inline-block;height: 38px;"
    >
      <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;" />
      <embed
        src="https://program.xinchacha.com/web/1517434654028689408=*.laidian360.com.svg"
        width="103"
        height="38"
        type="image/svg+xml"
        pluginspage="//www.adobe.com/svg/viewer/install/"
      >
    </a>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {}
  }
}
</script>
<style scoped>
#footer {
    width: 100%;
    height: 100%;
    color: #fff;
    background: #474747;
    overflow: hidden;
    text-align: center;
}

.logo {
    width: 95px;
    height: 45px;
    margin: 50px auto 20px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
}

.address_tel_fax {
    color: #d3d3d3;
    font-size: 14px;
    margin: 10px 0;
}

.email_wx {
    color: #d3d3d3;
    font-size: 14px;
}

.copy {
    color: #d3d3d3;
    font-size: 14px;
    margin: 50px 0 10px;
}

@media screen and (max-width: 997px) {
    .title {
        font-size: 20px;
    }

    .address_tel_fax {
        font-size: 12px;
    }

    .email_wx {
        font-size: 12px;
    }

    .copy {
        font-size: 12px;
        margin: 30px 0 10px;
    }
}
</style>
