import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '*',
      redirect: '/'
    }, {
      path: '/',
      name: 'PageView',
      component: (resolve) => require(['@/view/PageView'], resolve),
      children: [
        {
          path: '/',
          redirect: '/home'
        }, {
          path: '/home',
          name: 'home',
          component: (resolve) => require(['@/view/HomePage'], resolve),
          meta: {
            title: '首页'
          }
        },
        {
          path: '/software',
          name: 'software',
          component: (resolve) => require(['@/view/Software'], resolve),
          meta: {
            title: '方案'
          },
          children: [
            {
              path: '/software',
              redirect: '/software/smartTown'
            },
            {
              path: '/software/smartTown',
              name: 'software',
              component: (resolve) => require(['@/view/Software_smartTown'], resolve),
              meta: {
                title: '软件产品丨大数据选址系统'
              }
            },
            {
              path: '/software/bigData',
              name: 'software',
              component: (resolve) => require(['@/view/Software_bigData'], resolve),
              meta: {
                title: '软件产品丨运营管理系统'
              }
            }
          ]
        },
        {
          path: '/companyintroduction',
          name: 'companyintroduction',
          component: (resolve) => require(['@/view/CompanyIntroduction'], resolve),
          meta: {
            title: '关于我们'
          }
        },
        {
          path: '/customer',
          name: 'customer',
          component: (resolve) => require(['@/view/Customer'], resolve),
          meta: {
            title: '客户'
          }
        },
        {
          path: '/jobchance',
          name: 'jobchance',
          meta: {
            title: '工作机会'
          }
        },
        {
          path: '/cooperation',
          name: 'cooperation',
          component: (resolve) => require(['@/view/Cooperation'], resolve),
          meta: {
            title: '合作伙伴'
          }
        }
      ]
    }
  ]
})

// 进入路由前方法勾子
router.beforeEach((to, from, next) => {
  /*
    to 目标路由
    from 源路由
    next 跳转到下一个路由
    */
  console.log(to.name)
  if (to.name === 'jobchance') {
    window.location.href = 'https://www.zhipin.com/gongsi/9c3a924a3ce249991Hd_0tm5.html'
    return
  }
  // 如果不需要，则直接跳转到对应路由
  next()
})

// 进入路由后方法勾子
router.afterEach((to, from) => {

})

export default router
