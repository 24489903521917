// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'

/* 路由 */
import router from './router'

/* axios */
import axios from './api'
import api from './api/api'

/* swiper */
import 'swiper/dist/css/swiper.min.css'

/* 重置样式 */
import './assets/css/reset.min.css'

/* animate.css */
import 'animate.css'

/* 头部组件 */
import Header from './components/Header'

/* 尾部组件 */
import Footer from './components/Footer'

/* 回到顶部 */
import GoTop from './components/GoTop'

Vue.prototype.http = axios
Vue.prototype.api = api
Vue.component(Header.name, Header)
Vue.component(Footer.name, Footer)
Vue.component(GoTop.name, GoTop)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
