<template>
  <!-- 头部整体盒子 -->
  <div
    id="header"
    class="container-fuild"
  >
    <!-- 电脑导航 -->
    <div class="header-nav container hidden-xs">
      <!-- 导航logo -->
      <div class="header-nav-logo">
        <img src="@/assets/img/logo_black.png">
      </div>
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <li
          v-for="(item,index) in navList"
          :key="index"
          :class="index==navIndex?'active':''"
          @click="navClick(index,item.name)"
        >
          <router-link :to="item.path">
            {{ item.name }}
            <span
              v-if="item.children.length>0"
              class="glyphicon glyphicon-menu-down"
            />
            <i class="underline" />
          </router-link>
          <dl v-if="item.children.length>0">
            <dt
              v-for="(i,n) in item.children"
              :key="n"
            >
              <router-link :to="i.path">{{ i.name }}</router-link>
            </dt>
          </dl>
        </li>
        <li><span class="glyphicon glyphicon-earphone">&nbsp;021-62339576</span></li>
        <li><span class="glyphicon glyphicon-envelope">&nbsp;service@laidian360.com</span></li>
      </ul>
    </div>
    <!-- 手机导航 -->
    <div class="header-nav-m container-fuild visible-xs">
      <nav
        class="navbar navbar-default navbar-fixed-top"
        role="navigation"
      >
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span class="sr-only">导航条</span>
              <span class="icon-bar" />
              <span class="icon-bar" />
              <span class="icon-bar" />
            </button>
            <a
              class="navbar-brand"
              style="padding: 10px 15px;"
              href=""
            >
              <img
                class="center-block"
                style="height: 26px;"
                src="@/assets/img/logo_black_app.png"
              >
            </a>
            <a
              class="navbar-brand"
              style="font-weight: 100;color: #000;"
              href=""
            >
              让零售人牛起来
            </a>
          </div>
          <div
            id="nav-main"
            class="collapse navbar-collapse"
          >
            <ul
              id="nav-list"
              class="nav navbar-nav"
            >
              <!-- <li class="active"><a target="_blank" href=""></a></li>-->
              <li
                v-for="(item,index) in navList"
                :key="index"
                :class="index==navIndex?'active':''"
                data-toggle="collapse"
                data-target="#menu"
                @click="navClick(index,item.name)"
              >
                <router-link
                  :to="item.path"
                  :class="'active'"
                >
                  {{ item.name }}
                  <i class="underline" />
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>

import $ from 'jquery'

export default {
  name: 'Header',
  data () {
    return {
      navIndex: sessionStorage.getItem('navIndex') ? sessionStorage.getItem('navIndex') : 0,
      menuName: '上海来店信息技术有限公司',
      menuClass: 'glyphicon glyphicon-menu-down',
      navList: [
        {
          name: '首页',
          path: '/',
          children: []
        },
        {
          name: '方案',
          path: '/software',
          children: [
            {
              name: '大数据选址系统',
              path: '/software/smartTown'
            },
            {
              name: '运营管理系统',
              path: '/software/bigData'
            }
          ]
        },
        {
          name: '客户',
          path: '/customer',
          children: []
        },
        {
          name: '关于我们',
          path: '/companyintroduction',
          children: []
        },
        {
          name: '合作伙伴',
          path: '/cooperation',
          children: []
        },
        {
          name: '工作机会',
          path: '/jobchance',
          children: []
        }
        // {
        //     name: "软件产品",
        //     path: "/software",
        //     children: [
        //         {
        //             name: "大数据选址系统",
        //             path: "/software/smartTown"
        //         },
        //         {
        //             name: "运营管理系统",
        //             path: "/software/bigData"
        //         }
        //     ]
        // },
        // {
        //     name: "相关服务",
        //     path: "/service",
        //     children: []
        // },
        // {
        //     name: "新闻动态",
        //     path: "/newsinformation",
        //     children: []
        // },
        // {
        //     name: "联系我们",
        //     path: "/contactus",
        //     children: []
        // }
      ]
    }
  },
  methods: {
    navClick (index, name) {
      this.navIndex = index
      sessionStorage.setItem('navIndex', index)
      this.menuName = name

      $('#nav-main').collapse('hide')
    },
    mounted () {

    }
  }
}
</script>
<style scoped>
/* 顶部 */
#header {
  background: #192032;
  transition: all ease 0.6s;
  position: relative;
}

#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}

/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}

/* 导航栏 */
#header .header-nav {
  height: 60px;
}

/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 100px;
  height: 100%;
  float: left;
  position: relative;
}

/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 95px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}

#header .header-nav .header-nav-wrapper {
  line-height: 60px;
  float: right;
  margin: 0;
  /*max-width: 800px;*/
}

/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  color: #fff;
  float: left;
  margin: 0 15px;
  position: relative;
}

/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: #fff;
  font-weight: normal;
  padding: 15px 0;
  position: relative;
}

/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
  height: 4px;
  opacity: 0;
  transition: all 0.3s ease;
  background-color: #1e73be;
}

/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover {
  /*color: #1e73be;*/
  text-decoration: none;
  font-weight: bold;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}

/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  color: #fff;
  text-decoration: none;
  border-bottom: 4px solid #fff;
}

/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  /*top: 80%;*/
  left: 0;
  z-index: 999999;
  /*box-shadow: 0 0 3px 1px #ccc;*/
  background: #444444;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#header .header-nav .header-nav-wrapper > li > dl > dt > a {
  color: #fff;
  font-weight: normal;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}

#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
}

/* 移动端导航栏样式 */
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
    margin-bottom: 51px;
  }

  .nav > li,
  .nav > li > a {
    text-align: center;
    font-size: 16px;
  }
}
</style>
